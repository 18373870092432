import React from 'react'
import PropTypes from 'prop-types'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="content">
            <div className="inner">
                <h1>The Mighty Missoula</h1>
                <p>Instrumental post-rock band from the Pacific Northwest<br />
                </p>
            </div>
        </div>
        <nav className="main-nav">
            <ul>
                <li><a href="#music" onClick={(e) => {e.preventDefault(); props.onOpenArticle('music')}}>Discography</a></li>
                <li><a href="#videos" onClick={(e) => {e.preventDefault(); props.onOpenArticle('videos')}}>Videos</a></li>
                <li><a href="#shows" onClick={(e) => {e.preventDefault(); props.onOpenArticle('shows')}}>Shows</a></li>
                <li><a href="#merch" onClick={(e) => {e.preventDefault(); props.onOpenArticle('merch')}}>Merch</a></li>
                <li><a href="#about" onClick={(e) => {e.preventDefault(); props.onOpenArticle('about')}}>About</a></li>
                <li><a href="#contact" onClick={(e) => {e.preventDefault(); props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
