import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faBluesky, faBandcamp, faFacebook, faInstagram, faReddit, faThreads } from '@fortawesome/free-brands-svg-icons'

import pic01 from '../images/album-artwork/remnants-cover.jpg'
import au_nord_cover from '../images/album-artwork/au-nord-cover.jpg'
import pic02 from '../images/2018-live-show.jpg'
import pic03 from '../images/band-photos/2025-metrognome.jpg'
import pic04 from '../images/tmm-shirt01.jpg'
import pic05 from '../images/album-artwork/remnants-vinyl.jpg'
import pic06 from '../images/album-artwork/virga-cover.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="music" className={`${this.props.article === 'music' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Discography</h2>

          <span className="image main"><a href="https://mightymissoula.bandcamp.com/album/remnants" target="_blank" rel="noreferrer"><img src={pic06} alt="The Mighty Missoula, Virga" /></a></span>
          <h3><em>Virga (EP)</em>, April 2021</h3>
          <p>Like falling rain that evaporates before reaching its terrestrial destination, expectations of living a normal life vanished into thin air in early 2020. Forced to adapt to the changing winds, The Mighty Missoula produced their latest EP in home studios using remote recording capabilities. Virga, a term for the aforementioned evaporating rain, offers a meditative experience for listeners and represents the band’s first entirely atmospheric contribution to a quickly growing catalog.</p>

          <h4>Reviews</h4>
          <p>
            <ul>
              <li><a href="https://sinusoidalmusic.com/2021/07/15/the-mighty-missoula-virga-atmospheric/" target="_blank" rel="noreferrer">Sinusoidal Music</a></li>
              <li><a href="http://indiedockmusicblog.co.uk/?p=3477" target="_blank" rel="noreferrer">Indie Dock Music</a></li>
            </ul>
          </p>


          <p>Buy/stream <strong>Virga</strong> on <a href="https://mightymissoula.bandcamp.com/album/virga" target="_blank" rel="noreferrer">Bandcamp</a>, <a href="https://open.spotify.com/album/03ULB0Vz5CZG1qmRPPXdc4" target="_blank" rel="noreferrer">Spotify</a>, <a href="https://www.amazon.com/gp/product/B0911GND56/" target="_blank" rel="noreferrer">Amazon</a>, or <a href="https://music.apple.com/us/album/virga-ep/1560247483" target="_blank" rel="noreferrer">Apple Music</a></p>

          <hr />

          <span className="image main"><a href="https://mightymissoula.bandcamp.com/album/remnants" target="_blank" rel="noreferrer"><img src={pic01} alt="The Mighty Missoula, Remnants" /></a></span>
          <h3><em>Remnants (LP)</em>, April 2020</h3>
          <p>Portland’s The Mighty Missoula returns with a robust collection of instrumental explorations. A good soundtrack for those gloomy weather days in the Northwest, <em>Remnants</em> swings between atmospheric calmness and temperamental crescendos while containing pockets of positive energy without being overly dark.</p>
          <h4>Reviews</h4>
          <p>
            <ul>
              <li><a href="https://capturedhowls.com/2020/05/26/the-mighty-missoula-explain-their-new-records-immersively-thunderous-post-rock/" target="_blank" rel="noreferrer">Captured Howls</a></li>
              <li><a href="https://fvmusicblog.com/2020/04/16/the-mighty-missoula-remnants/" target="_blank" rel="noreferrer">FVMusicBlog</a></li>
              <li><a href="https://tometotheweathermachine.com/the-mighty-missoula-remnants/" target="_blank" rel="noreferrer">Tome to the Weather Machine</a></li>
              <li><a href="https://www.vrtxmag.com/artists/the-mighty-missoula/" target="_blank" rel="noreferrer">Vortex Magazine</a></li>
            </ul>
          </p>

          <p>Buy/stream <strong>Remnants</strong> on <a href="https://mightymissoula.bandcamp.com/album/remnants" target="_blank" rel="noreferrer">Bandcamp</a>, <a href="https://open.spotify.com/album/03ULB0Vz5CZG1qmRPPXdc4" target="_blank" rel="noreferrer">Spotify</a>, <a href="https://www.amazon.com/Remnants-Mighty-Missoula/dp/B085X3ZNH3" target="_blank" rel="noreferrer">Amazon</a>, <a href="https://music.apple.com/us/album/remnants/1502855573" target="_blank" rel="noreferrer">Apple Music</a>, or <a href="https://www.youtube.com/watch?v=LARDEySv69Y" target="_blank" rel="noreferrer">Youtube</a></p>

          <hr />

          <span className="image main"><a href="https://mightymissoula.bandcamp.com/" target="_blank" rel="noreferrer"><img src={au_nord_cover} alt="The Mighty Missoula, Au Nord (2017)" /></a></span>
          <h3><em>Au Nord (EP)</em>, March 2017</h3>

          <p>Buy/stream <strong>Au Nord</strong> on <a href="https://mightymissoula.bandcamp.com/album/au-nord" target="_blank" rel="noreferrer">Bandcamp</a>, <a href="https://open.spotify.com/album/3Y7YA7BdcY9hSLRoSQ2uoF" target="_blank" rel="noreferrer">Spotify</a>, <a href="https://www.amazon.com/Au-Nord-Mighty-Missoula/dp/B06XGCLB4V" target="_blank" rel="noreferrer">Amazon</a>, <a href="https://music.apple.com/us/album/au-nord/1212731108" target="_blank" rel="noreferrer">Apple Music</a>, or <a href="https://www.youtube.com/watch?v=96FsJRYhSvE" target="_blank" rel="noreferrer">Youtube</a></p>

          {close}
        </article>

        <article id="shows" className={`${this.props.article === 'shows' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Shows</h2>
          <span className="image main"><img src={pic02} alt="Performing at High Water Mark. Nov. 2018" /></span>

          <h3>Upcoming Shows</h3>
          <p>
            <ul>
              <li><em>Stay Tuned!</em></li>
            </ul>
          </p>

          <h3>Past Shows</h3>
          <p>
            <ul>
              <li><a href="https://www.songkick.com/concerts/42235979-mighty-missoula-at-high-water-mark-lounge">2024-11-23 @ High Water Mark, Portland, OR</a></li>
              <li><a href="https://www.songkick.com/concerts/41947108-young-elk-at-high-water-mark-lounge">2024-05-14 @ High Water Mark, Portland, OR</a></li>
              <li><a href="https://www.songkick.com/concerts/41550251-mighty-missoula-at-kellys-olympian">2023-12-01 @ Kelly's Olympian, Portland, OR</a></li>
              <li><a href="https://www.songkick.com/concerts/41119060-mighty-missoula-at-high-water-mark">2023-08-03 @ High Water Mark, Portland, OR</a></li>
              <li><a href="https://www.songkick.com/concerts/41119058-lehnen-at-aces-high-saloon">2023-08-02 @ Aces High Saloon, Salt Lake City, Utah</a></li>
              <li><a href="https://www.songkick.com/concerts/41139310-i-hear-sirens-usa-at-replay-lounge">2023-07-30 @ Replay Lounge, Lawrence, KS</a></li>
              <li><a href="https://www.thepostfestival.com/" target="_blank" rel="noreferrer">2023-07-28/29 @ POST. Festival, Indianapolis, Indiana USA</a></li>
              <li><a href="https://www.songkick.com/concerts/41242872-mighty-missoula-at-driftwood-char-bar">2023-07-26 @ Driftwood Char Bar, Minneapolis, MN</a></li>
              <li><a href="https://www.songkick.com/concerts/41204255-mighty-missoula-at-seventh-circle-music-collective">2023-07-24 @ Seventh Circle Music Collective, Denver, CO</a></li>
              <li><a href="https://www.songkick.com/concerts/41255365-mighty-missoula-at-foxhole" target="_blank" rel="noreferrer">2023-07-23 @ The Foxhole, Boise, ID</a></li>
              <li><a href="https://www.facebook.com/events/2555807767906914/" target="_blank" rel="noreferrer">2023-06-23/24 @ NW Post-Rock & Friends Fest 2023, Portland, OR</a></li>
              <li><a href="https://www.songkick.com/concerts/40840067-mobilities-at-high-water-mark" target="_blank" rel="noreferrer">2023-04-21 @ High Water Mark, Portland, OR</a></li>
              <li><a href="https://www.songkick.com/concerts/41023242-only-echoes-at-high-water-mark" target="_blank" rel="noreferrer">2023-04-11 @ High Water Mark, Portland, OR</a></li>
              <li><a href="https://www.songkick.com/concerts/40842897-ghost-forest-at-turn-turn-turn" target="_blank" rel="noreferrer">2023-03-30 @ Turn! Turn! Turn!, Portland, OR</a></li>
              <li><a href="https://www.songkick.com/concerts/40649183-daemones-at-no-fun" target="_blank" rel="noreferrer">2022-10-20 @ No Fun Bar, Portland, OR</a></li>
              <li><a href="https://www.facebook.com/events/1053317985021939/" target="_blank" rel="noreferrer">2020-02-18 @ The Liquor Store, Portland, OR</a></li>
              <li><a href="https://www.facebook.com/events/405676176809834/" target="_blank" rel="noreferrer">2019-10-17 @ No Fun Bar, Portland, OR</a></li>
              <li><a href="https://www.facebook.com/events/896924554022041/" target="_blank" rel="noreferrer">2019-11-14 @ High Water Mark, Portland, OR</a></li>
              <li><a href="https://www.facebook.com/events/379340189662156/" target="_blank" rel="noreferrer">2019-11-15 @ Old Nick's Pub, Eugene, OR</a></li>
              <li>2019-06-30 @ High Water Mark, Portland, OR</li>
              <li>2019-04-04 @ Bit House Saloon, Portland, OR</li>
              <li>2018-11-12 @ High Water Mark, Portland, OR</li>
              <li>2018-05-09 @ Kelly's Olympian, Portland, OR</li>
              <li>2018-04-21 @ NW Post Rock Fest 2018, Portland, OR</li>
              <li>2017-12-14 @ The Analog, Portland, OR</li>
              <li>2017-11-24 @ Kelly's Olympian, Portland, OR</li>
              <li>2017-07-22 @ The Analog, Portland, OR</li>
              <li>2017-07-13 @ Kelly's Olympian, Portland, OR</li>
              <li>2017-07-07 @ Sam Bond's Garage, Eugene, OR</li>
              <li>2017-06-11 @ The Secret Society, Portland, OR</li>
              <li>2017-04-30 @ Kelly's Olympian, Portland, OR</li>
              <li>2017-04-15 @ Kelly's Olympian, Portland, OR</li>
              <li>2017-03-03 @ The Tardis Room, Portland, OR</li>
              <li>2017-01-11 @ Kelly's Olympian, Portland, OR</li>
              <li>2016-12-08 @ Kelly's Olympian, Portland, OR</li>
              <li>2016-11-26 @ The Raven, Portland, OR</li>
              <li>2016-10-20 @ World Famous Kenton Club, Portland, OR</li>
              <li>2016-09-16 @ Ash Street, Portland, OR</li>
              <li>2016-09-14 @ Turn Turn Turn, Portland, OR</li>
            </ul>
          </p>

          <p>Follow on <a href="https://www.songkick.com/artists/8877794-mighty-missoula" target="_blank" rel="noreferrer">Songkick</a> and/or <a href="https://www.facebook.com/pg/mightymissoula/events/" target="_blank" rel="noreferrer">Facebook</a>.</p>
          {close}
        </article>


        <article id="videos" className={`${this.props.article === 'videos' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Videos</h2>

          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/sZLoc6phoyQ" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/hKdpfyZLaGs" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/TPK8t8fGFlU" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/A0eRI9oRWT8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/1S4oPdmLa4E" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/edBPT5es-r4" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/b3QXCPp-FTg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/Ou0pePlcEhM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>

          <div className="video-container">
            <iframe src="https://www.youtube.com/embed/6nXZBs6DqQw" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          {close}
        </article>

        <article id="merch" className={`${this.props.article === 'merch' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Merchandise</h2>

          <p>Order our latest album 'Remnants' via <a href="https://mightymissoula.bandcamp.com/album/remnants" target="_blank" rel="noreferrer">Bandcamp</a>.</p>

          <span className="image main"><a href="https://mightymissoula.bandcamp.com/album/remnants" target="_blank" rel="noreferrer"><img src={pic05} alt="" /></a></span>

          <p>Additionally, you can also <a href="https://mightymissoula.bandcamp.com/merch" target="_blank" rel="noreferrer">purchase shirts and stickers</a>.</p>

          <span className="image main"><a href="https://mightymissoula.bandcamp.com/merch" target="_blank" rel="noreferrer"><img src={pic04} alt="" /></a></span>

          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
          <span className="image main"><img src={pic03} alt="" /></span>
          <p>Like their namesake, which triggered the cataclysmic flooding of the Pacific Northwest, the music of The Mighty Missoula sways between atmospheric calmness and temperamental crescendos. They accomplish this while containing pockets of positive energy without being overly dark.</p>
          <p><em>"One of the things that brought us all to the Pacific Northwest is our love for the outdoors, which has been an underlying theme in our music,"</em> they explain. This is best portrayed in the sophomore release, Remnants (2020), in which they tied their anxiety surrounding the state of climate change.</p>
          <p>While social distancing during the pandemic, The Mighty Missoula produced their latest album in home studios using remote recording capabilities. Virga (2021), a term for evaporating rain, offers a meditative experience for listeners and represents the band's first entirely atmospheric contribution to their growing catalog.</p>
          <p>The instrumental band has had multiple tracks listed in the best of playlists on Spotify, including Fixed Point Music's Best Post-Rock of 2020 and 2021. Their song, Regression, from their debut album Au Nord (2017), was featured in A Thousand Arms Music's compilation, Open Language: Volume II.</p>
          <p>Founded in 2015, the Portland, Oregon-based band cites influences such as Mogwai, Godspeed You! Black Emperor, Mono, Jakob, This Will Destroy You, Red Sparowes, Hammock, Nils Frahm, and Robin Guthrie.</p>
          <p>They're currently producing their fourth album.</p>
          <p>Current Lineup:
            <ul>
              <li>Adam Schmid // drums + synths + percussion</li>
              <li>Eric Mapson // bass</li>
              <li>Robby Russell // guitar</li>
              <li>Sean Roberts // guitar</li>
            </ul>
          </p>

          <div className="video-container" style={{paddingBottom: '10%', maxWidth: '400px'}}>
            <iframe scrolling="no" id="bandcamp-follow" src="https://bandcamp.com/band_follow_button_classic/3797312032"></iframe>
          </div>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <h3>For general inquiries or booking:</h3>
          <p>General: <a href="mailto:band@mightymissoula.com">band@mightymissoula.com</a>.</p>
          <p>Booking: <a href="mailto:booking@mightymissoula.com">booking@mightymissoula.com</a>.</p>

          <p>p.s. if you're interested in having The Mighty Missoula contribute music to your film project, please reach out!</p>

          <ul className="icons">
            <li><a href="https://bsky.app/profile/mightymissoula.com" aria-label="Bluesky"><FontAwesomeIcon icon={faBluesky} size="1x" title="Bluesky" /></a></li>
            <li><a href="https://www.threads.net/@mightymissoula" aria-label="Threads"><FontAwesomeIcon icon={faThreads} size="1x" title="Threads" /></a></li>
            <li><a href="https://twitter.com/mightymissoula" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} size="1x" title="Twitter" /></a></li>
            <li><a href="https://mightymissoula.bandcamp.com/" aria-label="Bandcamp"><FontAwesomeIcon icon={faBandcamp} size="1x" title="Bandcamp" /></a></li>
            <li><a href="https://www.facebook.com/mightymissoula/" aria-label="Facebook"><FontAwesomeIcon icon={faFacebook} size="1x" title="Facebook" /></a></li>
            <li><a href="https://www.instagram.com/mightymissoula/" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} size="1x" title="Instagram" /></a></li>
            <li><a href="https://www.reddit.com/user/mightymissoula" aria-label="Reddit"><FontAwesomeIcon icon={faReddit} size="1x" title="Reddit" /></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
