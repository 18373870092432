import React from 'react'
import Layout from '../components/layout'

import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isArticleVisible: false,
      timeout: false,
      articleTimeout: false,
      article: '',
      loading: 'is-loading'
    }
    this.handleOpenArticle = this.handleOpenArticle.bind(this)
    this.handleCloseArticle = this.handleCloseArticle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handlePopState = this.handlePopState.bind(this);
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
        
        // Check for hash in URL and open corresponding article
        if (typeof window !== 'undefined') {
          const hash = window.location.hash.replace('#', '');
          const validSections = ['music', 'videos', 'shows', 'merch', 'about', 'contact'];
          
          if (hash && validSections.includes(hash)) {
            this.handleOpenArticle(hash);
          }
        }
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
    
    // Add popstate event listener to handle browser back/forward navigation
    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', this.handlePopState);
    }
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
    
    if (typeof window !== 'undefined') {
      window.removeEventListener('popstate', this.handlePopState);
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOpenArticle(article) {
    // Update URL hash
    if (typeof window !== 'undefined') {
      // Use replaceState to avoid browser history issues
      window.history.replaceState(null, null, `#${article}`);
    }

    this.setState({
      isArticleVisible: !this.state.isArticleVisible,
      article
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
    }, 350)
  }

  handleCloseArticle() {
    // Clear URL hash
    if (typeof window !== 'undefined') {
      window.history.replaceState(null, null, window.location.pathname);
    }

    this.setState({
      articleTimeout: !this.state.articleTimeout
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: ''
      })
    }, 350)
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
      }
    }
  }
  
  handlePopState(event) {
    // Handle browser back/forward navigation
    if (typeof window !== 'undefined') {
      const hash = window.location.hash.replace('#', '');
      const validSections = ['music', 'videos', 'shows', 'merch', 'about', 'contact'];
      
      if (hash && validSections.includes(hash)) {
        // Only open if it's different from current
        if (this.state.article !== hash) {
          this.handleOpenArticle(hash);
        }
      } else {
        // No hash means we should close any open article
        if (this.state.isArticleVisible) {
          this.handleCloseArticle();
        }
      }
    }
  }

  // Get page title and description based on current article
  getMetadata() {
    const { article } = this.state;
    const metadata = {
      title: null,
      description: null
    };

    switch (article) {
      case 'music':
        metadata.title = 'Discography';
        metadata.description = 'Albums and music from The Mighty Missoula, an instrumental post-rock band from Portland, Oregon';
        break;
      case 'videos':
        metadata.title = 'Videos';
        metadata.description = 'Watch videos and live performances by The Mighty Missoula, a cinematic instrumental post-rock band';
        break;
      case 'shows':
        metadata.title = 'Tour Dates & Shows';
        metadata.description = 'Upcoming concerts, tour dates, and past performances by The Mighty Missoula';
        break;
      case 'merch':
        metadata.title = 'Merchandise';
        metadata.description = 'Official merchandise, albums, and apparel from The Mighty Missoula';
        break;
      case 'about':
        metadata.title = 'About the Band';
        metadata.description = 'Learn about The Mighty Missoula, an instrumental post-rock band from Portland, Oregon';
        break;
      case 'contact':
        metadata.title = 'Contact';
        metadata.description = 'Get in touch with The Mighty Missoula for bookings, inquiries, or film scoring opportunities';
        break;
      default:
        metadata.title = null;
        metadata.description = null;
    }

    return metadata;
  }

  render() {
    const metadata = this.getMetadata();
    
    return (
      <Layout 
        location={this.props.location}
        articleTitle={metadata.title}
        articleDescription={metadata.description}
      >
        <div className={`body ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
          <div id="wrapper">
            <Header onOpenArticle={this.handleOpenArticle} timeout={this.state.timeout} />
            <Main
              isArticleVisible={this.state.isArticleVisible}
              timeout={this.state.timeout}
              articleTimeout={this.state.articleTimeout}
              article={this.state.article}
              onCloseArticle={this.handleCloseArticle}
              setWrapperRef={this.setWrapperRef}
            />
            <Footer timeout={this.state.timeout} />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
